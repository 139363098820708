import axios from "./client";
import { OrderActions } from "../reducers/OrderReducer";
import store from "./../store";
import FileDownload from "js-file-download";
const API_URL = process.env.REACT_APP_API_URL;

export const fetchOrders = async () => {
  store.dispatch({ type: OrderActions.RequestOrders, payload: {} });
  const { data: orders } = await axios.get<Order[]>(`${API_URL}orders`);
  store.dispatch({
    type: OrderActions.ReceiveOrders,
    payload: orders,
  });
};

export const updateOrder = async (order: Partial<Order>, id: string) => {
  try {
    store.dispatch({ type: OrderActions.RequestOrders, payload: {} });
    const { data: updatedOrder } = await axios.patch<Order>(
      `${API_URL}orders/${id}`,
      order
    );
    store.dispatch({
      type: OrderActions.AddOrder,
      payload: updatedOrder,
    });
  } catch (error) {
    store.dispatch({
      type: OrderActions.SetError,
      payload: "Hubo un error",
    });
  }
};
export const generateOrderPDF = (id: string, downloadName: string) => {
  axios
    .get(API_URL + `orders/${id}/pdf`, {
      responseType: "blob",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${downloadName}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
};

export const generateOrdersXls = async (downloadName: string) => {
  await axios.get(API_URL + `orders/xls/report`, {
    responseType: "blob",
    headers: {
      "content-type": "application/vnd.ms-excel;charset=UTF-8",
    },
  }).then((res) => {
    FileDownload(res.data, `${downloadName}.xlsx`);
  });
};

export const generateOrderXLS = async (id: string, downloadName: string) => {
  const res = await axios.get(API_URL + `orders/${id}/xls`, {
    responseType: "blob",
    headers: {
      "content-type": "application/vnd.ms-excel;charset=UTF-8",
    },
  });
  FileDownload(res.data, `${downloadName}.xlsx`);
};

export const sendToLaudus = async (id: string, branch: string) => {
  
  try {
    store.dispatch({ type: OrderActions.RequestOrders, payload: {} });
    const { data: updatedOrder } = await axios.post(API_URL + `orders/laudus`, {
      id,
      branch
    })
    store.dispatch({
      type: OrderActions.AddOrder,
      payload: updatedOrder,
    });
  } catch (error: any) {
    console.log("pasando por aqui")
    console.log(error);  
      try {    
      alert(error!.response!.data.msg);
      store.dispatch({
        type: OrderActions.SetError,
        payload: (error as any).response.data.msg,
      });
    } catch(error) {
      // Si no hay respuesta del servidor, muestra un mensaje genérico
      alert("Hubo un error enviando el pedido a laudus");
      store.dispatch({
        type: OrderActions.SetError,
        payload: "Hubo un error enviando la orden a Laudus",
      });
    }
  }
}