import {
    DataGrid,
    GridColDef,
    esES,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useAppSelector } from '../../../../hooks/storeHooks';
import { useEffect, useState } from 'react';
import DetailedOrderView from '../../Common/Order/DetailedOrderView';
import handleGenerateExcel from '../../../../utils/generateOrdersWithoutInvoicesExcel';
import { Button, Checkbox, FormControlLabel } from '@mui/material';


function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
        </GridToolbarContainer>
    );
}

const useColumns = () => {
    const columns: GridColDef[] = [
        {
            field: 'id',
            minWidth: 200,
            flex: 1,
            headerName: 'Numero Referencia',

            valueGetter(params) {
                const date = new Date(params.row.date);
                return date.getTime();
            },
        },
        {
            field: 'date',
            minWidth: 100,
            flex: 1,
            headerName: 'Fecha',
            valueGetter(params) {
                const date = new Date(params.row.date);
                return `${date.getDate()}/${date.getMonth() + 1
                    }/${date.getFullYear()} `;
            },
            sortComparator: (v1, v2) => {
                const date1 = new Date(
                    `${v1.split('/')[1]}-${v1.split('/')[0]}-${v1.split('/')[2]}`
                );
                const date2 = new Date(
                    `${v2.split('/')[1]}-${v2.split('/')[0]}-${v2.split('/')[2]}`
                );
                console.log(date1, date2);
                const date1Time = date1.getTime();
                const date2Time = date2.getTime();
                return date1Time - date2Time;
            },
        },
        {
            field: 'hour',
            minWidth: 100,
            flex: 1,
            headerName: 'Hora',
            valueGetter(params) {
                const date = new Date(params.row.date);
                const hours = date.getHours().toString().padStart(2, '0');
                const minutes = date.getMinutes().toString().padStart(2, '0');
                return `${hours}:${minutes}`;
            },
        },
        {
            field: 'customer',
            minWidth: 200,
            flex: 1,
            headerName: 'Cliente',
            valueGetter(params) {
                return params.row.customer.name;
            },
        },
        {
            field: 'total',
            minWidth: 100,
            flex: 1,
            headerName: 'Total Neto',
            valueGetter(params) {
                return params.row.discountedNetTotal.toLocaleString('es-CL', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                });
            },
            sortComparator: (v1, v2) => {
                // Convertimos el formato de texto a números para realizar la comparación
                const value1 = Number(v1.replace(/\./g, '')); // Quitamos puntos (separadores de miles)
                const value2 = Number(v2.replace(/\./g, ''));

                return value1 - value2; // Ordenar de menor a mayor
            },

        },

        {
            field: 'totalProducts',
            minWidth: 100,
            flex: 1,
            headerName: 'Total Productos',
            valueGetter(params) {
                return params.row.products.length;
            },
        }
    ]
    return columns;
}

const useModalState = () => {
    const [openModal, setOpenModal] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);

    return { openModal, setOpenModal, selectedOrder, setSelectedOrder };
};


const useDataGrid = (columns: GridColDef[], rows: Order[]) => {
    const { openModal, setOpenModal, selectedOrder, setSelectedOrder } =
        useModalState();
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [filteredRows, setFilteredRows] = useState<Order[]>(rows);
    const [missingLaudusId, setMissingLaudusId] = useState(false);

    useEffect(() => {
        let filtered = rows;
        if (startDate) {
            filtered = filtered.filter((row) => new Date(row.date) >= startDate);
        }
        if (endDate) {
            filtered = filtered.filter((row) => new Date(row.date) <= endDate);
        }
        if (missingLaudusId) {
            filtered = filtered.filter((row) => !row.laudusId);
        }
        setFilteredRows(filtered);
    }, [startDate, endDate, rows, missingLaudusId]);

    const handleExportToExcel = async () => {
        await handleGenerateExcel(filteredRows);
    };

    return (
        <>
            <div className="my-2">Total: {Math.round(filteredRows.reduce(
                (acc, row) => acc + row.discountedNetTotal,
                0
            )).toLocaleString("es-CL")}</div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <div className="my-2 flex gap-2">
                    <DatePicker
                        sx={{ minWidth: '200px' }}
                        format='dd/MM/yyyy'
                        label="Fecha de inicio"
                        value={startDate}
                        onChange={(newValue) => {
                            // set hours and minutes to 0 to avoid problems with the time
                            if (newValue) {
                                newValue.setHours(0);
                                newValue.setMinutes(0);
                            }
                            setStartDate(newValue)
                        }}
                    />
                    <DatePicker
                        sx={{ minWidth: '200px' }}
                        format='dd/MM/yyyy'
                        label="Fecha de fin"
                        value={endDate}
                        onChange={(newValue) => {
                            // set hours and minutes to 23:59 to avoid problems with the time
                            if (newValue) {
                                newValue.setHours(23);
                                newValue.setMinutes(59);
                            }
                            setEndDate(newValue)
                        }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={missingLaudusId}
                                onChange={(e) => setMissingLaudusId(e.target.checked)}
                                color="primary"
                            />
                        }
                        label="Filtrar sin LaudusId"
                    />
                    <Button variant="contained" onClick={handleExportToExcel} disabled={filteredRows.length === 0}>
                        Descargar Excel
                    </Button>

                </div>
            </LocalizationProvider>
            <DataGrid
                sx={{
                    padding: 2,
                    '& .sentToERP': {
                        bgcolor: '#9ACD32',
                    },
                    '& .blocked': {
                        bgcolor: '#FF0000',
                    },
                    '& .sinLaudusId': {
                        bgcolor: '#87CEEB',
                    },
                }}
                getRowClassName={(params) => {
                    if (!params.row.laudusId && params.row.sentToERP) {
                        return 'sinLaudusId';
                    }
                    if (params.row.blocked) {
                        return 'blocked';
                    }
                    if (params.row.sentToERP) {
                        return 'sentToERP';
                    }
                    return '';
                }

                }
                autoHeight={true}
                columns={columns}
                rows={filteredRows}
                pageSize={20}
                getRowId={(row) => row._id}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                components={{ Toolbar: CustomToolbar }}
                onRowDoubleClick={(params) => {
                    setSelectedOrder(params.row);
                    setOpenModal(true);
                }}
            />
            <DetailedOrderView
                open={openModal}
                onClose={() => setOpenModal(false)}
                order={selectedOrder}
            ></DetailedOrderView>
        </>
    );
};

const OrdersWithoutInvoicesView = () => {

    const { ordersWithNoInvoice } = useAppSelector((s: any) => s.pendingReducer);
    const columns = useColumns();

    return useDataGrid(columns, ordersWithNoInvoice);
}

export default OrdersWithoutInvoicesView