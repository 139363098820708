import { useState } from "react";
import { Box, Tab, Tabs, Button } from "@mui/material";
import Icon from "@mdi/react";
import { mdiCircleSlice1 } from "@mdi/js";
import { syncInventories } from "../../../../services/inventoryService"; // Ajusta la ruta según corresponda
import InventoriesByFilterView from "./InventoriesByFilterView";
import InventoriesBySkuView from "./InventoriesBySkuView";
import TopSkuDifferenceView from "./TopDifferencesView";

const InventoryView: React.FC = () => {
    const [tab, setTab] = useState<"inventory" | "sku" | "difference">("inventory");
    const [isSyncing, setIsSyncing] = useState(false);

    const handleSync = async () => {
        setIsSyncing(true);
        try {
            await syncInventories();
        } catch (error) {
            console.error("Error al sincronizar inventarios:", error);
        } finally {
            setIsSyncing(false);
        }
    };

    return (
        <div className="p-5 h-full overflow-auto">
            <div className="text-xl mb-3">Gestión de Inventarios</div>
            <div className="mb-4 flex justify-end">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSync}
                    disabled={isSyncing}
                    startIcon={isSyncing && <Icon path={mdiCircleSlice1} spin size={1} />}
                >
                    {isSyncing ? "Sincronizando..." : "Sincronizar Inventarios"}
                </Button>
            </div>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                    value={tab}
                    onChange={(_, newValue) => setTab(newValue as "inventory" | "sku" | "difference")}
                    aria-label="tabs example"
                >
                    <Tab label="Vista de Inventarios" value="inventory" />
                    <Tab label="Vista por SKU" value="sku" />
                    <Tab label="Diferencias" value="difference" />
                </Tabs>
            </Box>
            <div className="overflow-auto mt-4">
                {tab === "inventory" && <InventoriesByFilterView />}
                {tab === "sku" && <InventoriesBySkuView />}
                {tab === "difference" && <TopSkuDifferenceView />}
            </div>
        </div>
    );
};

export default InventoryView;
