import axios from "./client";
import { PendingActions } from "../reducers/PendingReducer";
import store from "./../store";

const API_URL = process.env.REACT_APP_API_LOGISTICS_URL;

export const fetchOrdersWithNoInvoice = async () => {
    try {
    store.dispatch({ type: PendingActions.RequestOrdersWithNoInvoice, payload: {} });
  const { data: orders } = await axios.get(`${API_URL}orders/withoutInvoices`);
    store.dispatch({
        type: PendingActions.ReceiveOrdersWithNoInvoice,
        payload: orders,
    });
    }
    catch (error) {
        alert("Hubo un error");
    store.dispatch({
        type: PendingActions.ReceiveOrdersWithNoInvoice,
        payload: [],
    });
    }
};