import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const handleGenerateTopDifferencesExcel = async (data) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Diferencias de Stock');

    worksheet.columns = [
        { header: 'SKU', key: 'sku', width: 15 },
        { header: 'Bodega', key: 'warehouse', width: 20 },
        { header: 'Fecha', key: 'date', width: 15 },
        { header: 'Notas', key: 'notes', width: 30 },
        { header: 'ID de Inventario', key: 'inventoryId', width: 20 },
        { header: 'Stock Antiguo', key: 'oldStock', width: 15 },
        { header: 'Stock Nuevo', key: 'newStock', width: 15 },
        { header: 'Diferencia', key: 'stockDifference', width: 15 },
    ];

    data.forEach((item) => {
        worksheet.addRow({
            sku: item.sku,
            warehouse: item.warehouse,
            date: new Date(item.date).toLocaleDateString(), // Formato de fecha
            notes: item.notes,
            inventoryId: item.inventoryId,
            oldStock: item.oldStock,
            newStock: item.newStock,
            stockDifference: item.stockDifference,
        });
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, 'Top_Diferencias_Stock.xlsx');
};

export default handleGenerateTopDifferencesExcel;
