import { useEffect, useState } from 'react';
import {
    DataGrid,
    GridColDef,
    esES,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Button, Typography } from '@mui/material';
import { getTopSkuByStockDifference } from '../../../../services/inventoryService'; // Ajusta la ruta según tu estructura
import handleGenerateTopDifferencesExcel from '../../../../utils/generateTopDifferencesExcel';


const CustomToolbar = () => (
    <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
    </GridToolbarContainer>
);

const TopSkuDifferenceView: React.FC = () => {
    const [startDate, setStartDate] = useState<Date | null>(new Date(new Date().setDate(1)));
    const [endDate, setEndDate] = useState<Date | null>(new Date());
    const [topDifferences, setTopDifferences] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);

    const handleFetchData = async () => {
        if (!startDate || !endDate) {
            alert("Selecciona una fecha de inicio y fin.");
            return;
        }

        setLoading(true);
        try {
            const data = await getTopSkuByStockDifference(startDate, endDate);
            setTopDifferences(data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleExportToExcel = async () => {
        await handleGenerateTopDifferencesExcel(topDifferences);
    };

    const columns: GridColDef[] = [
        { field: 'sku', headerName: 'SKU', flex: 1, minWidth: 120 },
        { field: 'warehouse', headerName: 'Bodega', flex: 1, minWidth: 150 },
        { field: 'date', headerName: 'Fecha', flex: 1, minWidth: 120, valueGetter: (params) => new Date(params.value).toLocaleDateString() },
        { field: 'notes', headerName: 'Notas', flex: 1, minWidth: 200 },
        { field: 'inventoryId', headerName: 'ID de Inventario', flex: 1, minWidth: 150 },
        { field: 'oldStock', headerName: 'Stock Antiguo', flex: 1, minWidth: 100 },
        { field: 'newStock', headerName: 'Stock Nuevo', flex: 1, minWidth: 100 },
        { field: 'stockDifference', headerName: 'Diferencia', flex: 1, minWidth: 100 },
    ];

    return (
        <div className="p-5 h-full overflow-auto">
            <Typography variant="h6">Top Diferencias de Stock por SKU</Typography>
            <div className="my-2 flex gap-2">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="Fecha de inicio"
                        value={startDate}
                        format='dd/MM/yyyy'
                        onChange={(newValue) => setStartDate(newValue)}
                    />
                    <DatePicker
                        label="Fecha de fin"
                        value={endDate}
                        format='dd/MM/yyyy'
                        onChange={(newValue) => setEndDate(newValue)}
                    />
                </LocalizationProvider>
                <Button variant="contained" onClick={handleFetchData} disabled={loading || !startDate || !endDate}>
                    Buscar
                </Button>
                <Button variant="outlined" onClick={handleExportToExcel} disabled={loading || topDifferences.length === 0}>
                    Descargar Excel
                </Button>
            </div>
            <DataGrid
                autoHeight
                rows={topDifferences}
                columns={columns}
                pageSize={10}
                loading={loading}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                components={{
                    Toolbar: CustomToolbar,
                }}
                getRowId={(row) => row.inventoryId + row.warehouse + row.date + row.sku}
            />
        </div>
    );
};

export default TopSkuDifferenceView;
