export enum ManagerRoutes {
  Orders = "/manager/orders",
  Sellers = "/manager/sellers",
  Products = "/manager/products",
  Clients = "/manager/customers",
  Quotations = "/manager/quotations",
  Reports = "/manager/reports",
  Supervisors = "/manager/supervisors",
  Typists = "/manager/typists",
  Invoices = "/manager/invoices",
  ProductList = "/manager/productList",
  Statistics = "/manager/statistics",
  Pendings = "/manager/pendings",
  Inventories = "/manager/inventories",
}

export enum SupervisorRoutes {
  Reports = "/supervisor/reports",
}

export enum TypistRoutes {
  Orders = "/typist/orders",
}

export enum LogisticRoutes {
  Logistics = "/logistic/logistics",
  Trucks = "/logistic/trucks",
}

export enum Routes {
  Home = "/",
  Login = "/login",
}
