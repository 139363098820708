import { initLogin } from "./services/authService";
import { useEffect, useState } from "react";
import { mdiCircleSlice1 } from "@mdi/js";
import Icon from "@mdi/react";
import Login from "./components/Login/Login";
import { useAppSelector } from "./hooks/storeHooks";
import { BrowserRouter, Route, Routes as AppRoutes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";
import Layout from "./components/Layout";
import { Routes, ManagerRoutes, SupervisorRoutes, LogisticRoutes, TypistRoutes } from "./config/Routes";
import ProtectedRoute from "./components/ProtectedRoute";
import "./App.css"
import Home from "./components/Home/Home";
import SellersView from "./components/views/Manager/Sellers/SellersView";
import ProductView from "./components/views/Manager/Products/ProductView";
import CustomerView from "./components/views/Manager/Customers/CustomerView";
import OrdersView from "./components/views/Manager/Orders/OrdersView";
import QuotationsView from "./components/views/Manager/Quotations/QuotationsView";
import ReportsView from "./components/views/Manager/Reports/ReportsView";
import SupervisorsView from "./components/views/Manager/Supervisors/SupervisorsView";
import SupervisorReportsView from "./components/views/Supervisor/Reports/ReportsView";
import InvoicesView from "./components/views/Manager/Invoices/InvoicesView";
import TypistView from "./components/views/Manager/Typists/TypistsView";
import TypistOrdersView from "./components/views/Typist/Orders/TypistOrdersView";
import { ProductList } from "./components/views/Manager/ProductListFIles/ProductList";
import StatisticsView from "./components/views/Manager/Statistics/StatisticsView";
import PendingOrdersView from "./components/views/Manager/Pendings/PendingOrdersView";
import InventoryView from "./components/views/Manager/Inventories/InventoriesView";



function App() {

    const [loading, setLoading] = useState(true);
    const role = useAppSelector((s) => s.authReducer.user?.role);

    useEffect(() => {
        const init = async () => {
            setLoading(true);
            initLogin().finally(() => setLoading(false));
        };
        init();
    }, []);


    const theme = createTheme({
        palette: {
            background: {
                default: "#E5E5E5",
            },
            primary: {
                main: "#003755",
            },
            secondary: {
                main: "#388e3c",
            },
        },
    });
    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <Layout>

                    <AppRoutes>
                        <Route
                            path={Routes.Home}
                            element={<ProtectedRoute element={<Home />}></ProtectedRoute>}
                        ></Route>
                        <Route
                            path={ManagerRoutes.Orders}
                            element={
                                <ProtectedRoute element={<OrdersView />}></ProtectedRoute>
                            }
                        ></Route>
                        <Route
                            path={ManagerRoutes.Quotations}
                            element={
                                <ProtectedRoute element={<QuotationsView />}></ProtectedRoute>
                            }
                        ></Route>
                        <Route
                            path={ManagerRoutes.Sellers}
                            element={
                                <ProtectedRoute element={<SellersView />}></ProtectedRoute>
                            }
                        ></Route>
                        <Route
                            path={ManagerRoutes.Supervisors}
                            element={
                                <ProtectedRoute element={<SupervisorsView />}></ProtectedRoute>
                            }
                        ></Route>
                        <Route
                            path={ManagerRoutes.Typists}
                            element={
                                <ProtectedRoute element={<TypistView />}></ProtectedRoute>
                            }
                        ></Route>
                        <Route
                            path={ManagerRoutes.Products}
                            element={
                                <ProtectedRoute element={<ProductView />}></ProtectedRoute>
                            }
                        ></Route>
                        <Route
                            path={ManagerRoutes.ProductList}
                            element={
                                <ProtectedRoute element={<ProductList />}></ProtectedRoute>
                            }
                        ></Route>

                        <Route
                            path={ManagerRoutes.Clients}
                            element={
                                <ProtectedRoute element={<CustomerView />}></ProtectedRoute>
                            }
                        ></Route>
                        <Route
                            path={ManagerRoutes.Reports}
                            element={
                                <ProtectedRoute element={<ReportsView />}></ProtectedRoute>
                            }
                        ></Route>
                        <Route
                            path={ManagerRoutes.Statistics}
                            element={
                                <ProtectedRoute element={<StatisticsView />}></ProtectedRoute>
                            }
                        ></Route>
                        <Route
                            path={ManagerRoutes.Invoices}
                            element={
                                <ProtectedRoute element={<InvoicesView />}></ProtectedRoute>
                            }
                        ></Route>
                        <Route
                            path={TypistRoutes.Orders}
                            element={<ProtectedRoute element={<TypistOrdersView />}></ProtectedRoute>}
                        ></Route>
                        <Route
                            path={SupervisorRoutes.Reports}
                            element={<ProtectedRoute element={<SupervisorReportsView />}></ProtectedRoute>}
                        ></Route>
                        <Route
                            path={ManagerRoutes.Pendings}
                            element={<ProtectedRoute element={<PendingOrdersView />}></ProtectedRoute>}
                        ></Route>
                        <Route
                            path={ManagerRoutes.Inventories}
                            element={<ProtectedRoute element={<InventoryView />}></ProtectedRoute>}
                        ></Route>
                        <Route path={Routes.Login} element={<Login />}></Route>
                    </AppRoutes>
                </Layout>
            </ThemeProvider>
        </BrowserRouter>
    );
}



export default App;
