import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';

interface Props {
    open: boolean;
    onClose: () => void;
    order: OrderWithInvoiceAndPendingProducts | null;
}

const PendingProductsModal: React.FC<Props> = ({ open, onClose, order }) => {
    const calculateTotalPending = () => {
        if (!order) return 0;
        return order.pendingProducts.reduce((total, product) => {
            return total + product.quantity * product.netPrice;
        }, 0);
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Productos Pendientes</DialogTitle>
            <DialogContent>
                <div className="flex flex-col gap-2 mt-5">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Codigo</TableCell>
                                <TableCell>Descripcion</TableCell>
                                <TableCell>Precio Neto</TableCell>
                                <TableCell>Cantidad Pendiente</TableCell>
                                <TableCell>Total Pendiente</TableCell>
                            </TableRow>
                        </TableHead>
                        {order?.pendingProducts.map((product) => (
                            <TableRow key={product.code}>
                                <TableCell>{product.code}</TableCell>
                                <TableCell>{product.description}</TableCell>
                                <TableCell>
                                    {product.netPrice.toLocaleString('es-CL', {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                </TableCell>
                                <TableCell>{product.quantity}</TableCell>
                                <TableCell>
                                    {(product.quantity * product.netPrice).toLocaleString(
                                        'es-CL',
                                        {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                        }
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </Table>
                </div>
                <div className="mt-5">
                    <strong>Total Pendiente:</strong>{' '}
                    {calculateTotalPending().toLocaleString('es-CL', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                    })}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cerrar</Button>
            </DialogActions>
        </Dialog>
    );
};

export default PendingProductsModal;
