import {
    DataGrid,
    GridColDef,
    esES,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Box, Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { getInventoriesByFilter } from '../../../../services/inventoryService'; // Adjust path as necessary
import InventoryDetailModal from './InventoryDetailModal';

const warehouses = [
    { warehouseId: "001", name: "ARAUCO 960" },
    { warehouseId: "002", name: "SAN ANTONIO" },
    { warehouseId: "006", name: "MERMA" },
    { warehouseId: "007", name: "DIF SANTIAGO" },
    { warehouseId: "008", name: "DIF SAN ANTONIO" },
    { warehouseId: "009", name: "IMPORTACION NOV 2023" },
    { warehouseId: "00A", name: "DESCONTINUADOS 2024" },
    { warehouseId: "00B", name: "ARTURO PRAT 1939" },
    { warehouseId: "00C", name: "TRANSITORIA 2024" },
];

const InventoriesByFilterView = () => {
    const [startDate, setStartDate] = useState<Date | null>(new Date(new Date().setDate(1)));
    const [endDate, setEndDate] = useState<Date | null>(new Date());
    const [selectedWarehouse, setSelectedWarehouse] = useState<string>("");
    const [inventories, setInventories] = useState<any[]>([]);
    const [openModal, setOpenModal] = useState(false);
    const [selectedInventory, setSelectedInventory] = useState<any | null>(null);

    const handleFetchInventories = async () => {
        if (startDate && endDate) {
            const data = await getInventoriesByFilter(startDate, endDate, selectedWarehouse);
            setInventories(data);
        }
    };

    useEffect(() => {
        handleFetchInventories();
    }, []);

    const columns: GridColDef[] = [
        { field: 'warehouse', headerName: 'Bodega', flex: 1, minWidth: 150 },
        { field: 'date', headerName: 'Fecha', flex: 1, minWidth: 150, valueGetter: ({ value }) => new Date(value).toLocaleDateString() },
        { field: 'notes', headerName: 'Notas', flex: 2, minWidth: 300 },
        {
            field: 'details',
            headerName: 'Detalles',
            renderCell: (params) => (
                <Button
                    variant="contained"
                    onClick={() => {
                        setSelectedInventory(params.row);
                        setOpenModal(true);
                    }}
                >
                    Ver Detalle
                </Button>
            ),
            flex: 1,
            minWidth: 100,
        },
    ];

    return (
        <Box className="p-5 h-full overflow-auto">
            <div className="mb-4 flex gap-2">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="Fecha de Inicio"
                        format='dd/MM/yyyy'
                        value={startDate}
                        onChange={(date) => setStartDate(date)} />
                    <DatePicker
                        label="Fecha de Fin"
                        format='dd/MM/yyyy'
                        value={endDate}
                        onChange={(date) => setEndDate(date)} />
                </LocalizationProvider>
                <FormControl sx={{ minWidth: '200px' }}>
                    <InputLabel>Bodega</InputLabel>
                    <Select
                        value={selectedWarehouse}
                        onChange={(e) => setSelectedWarehouse(e.target.value)}
                        label="Bodega"
                    >
                        <MenuItem value="">Todos</MenuItem>
                        {warehouses.map((wh) => (
                            <MenuItem key={wh.warehouseId} value={wh.name}>
                                {wh.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button variant="contained" color="primary" onClick={handleFetchInventories}>
                    Actualizar
                </Button>
            </div>
            <DataGrid
                rows={inventories}
                columns={columns}
                pageSize={10}
                getRowId={(row) => row.inventoryId}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                components={{
                    Toolbar: CustomToolbar,
                }}
                autoHeight
            />
            <InventoryDetailModal
                open={openModal}
                onClose={() => setOpenModal(false)}
                inventory={selectedInventory}
            />
        </Box>
    );
};

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
        </GridToolbarContainer>
    );
}

export default InventoriesByFilterView;
