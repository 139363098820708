interface State {
    ordersWithNoInvoice: Order[];
    isLoadingOrdersWithNoInvoice: boolean;
    pendingSkus: PendingSku[];
    isLoadingPendingSkus: boolean;
    ordersWithInvoiceAndPendingProducts: OrderWithInvoiceAndPendingProducts[];
    isLoadingOrdersWithInvoiceAndPendingProducts: boolean;
}

const initialState: State = {
    ordersWithNoInvoice: [],
    isLoadingOrdersWithNoInvoice: false,
    pendingSkus: [],
    isLoadingPendingSkus: false,
    ordersWithInvoiceAndPendingProducts: [],
    isLoadingOrdersWithInvoiceAndPendingProducts: false,
};

export enum PendingActions {
    RequestOrdersWithNoInvoice = "REQUEST_ORDERS_WITH_NO_INVOICE",
    ReceiveOrdersWithNoInvoice = "RECEIVE_ORDERS_WITH_NO_INVOICE",
    RequestPendingSkus = "REQUEST_PENDING_SKUS",
    ReceivePendingSkus = "RECEIVE_PENDING_SKUS",
    RequestOrdersWithInvoiceAndPendingProducts = "REQUEST_ORDERS_WITH_INVOICE_AND_PENDING_PRODUCTS",
    ReceiveOrdersWithInvoiceAndPendingProducts = "RECEIVE_ORDERS_WITH_INVOICE_AND_PENDING_PRODUCTS",
}

const reducer = (
    state: State = initialState,
    { type, payload }: { type: PendingActions; payload: any }
): State => {
    switch (type) {
        case PendingActions.RequestOrdersWithNoInvoice:
            return { ...state, isLoadingOrdersWithNoInvoice: true };
        case PendingActions.ReceiveOrdersWithNoInvoice:
            return { ...state, ordersWithNoInvoice: payload, isLoadingOrdersWithNoInvoice: false };
        case PendingActions.RequestPendingSkus:
            return { ...state, isLoadingPendingSkus: true };
        case PendingActions.ReceivePendingSkus:
            return { ...state, pendingSkus: payload, isLoadingPendingSkus: false };
        case PendingActions.RequestOrdersWithInvoiceAndPendingProducts:
            return { ...state, isLoadingOrdersWithInvoiceAndPendingProducts: true };
        case PendingActions.ReceiveOrdersWithInvoiceAndPendingProducts:
            return { ...state, ordersWithInvoiceAndPendingProducts: payload, isLoadingOrdersWithInvoiceAndPendingProducts: false };
        default:
            return state;
    }
};

export default reducer;