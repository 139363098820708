import Logo from "../../../Logo";
import { Box, Button, Tab, Tabs } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { useAppSelector } from "../../../../hooks/storeHooks";
import { mdiCircleSlice1 } from "@mdi/js";
import Icon from "@mdi/react";

import { fetchOrdersWithNoInvoice } from "../../../../services/pendingService";
import OrdersWithoutInvoicesView from "./OrdersWithoutInvoicesView";
import { fetchOrdersWithInvoiceAndPendingProducts, fetchPendingSkus, syncInvoicesLaudus } from "../../../../services/invoiceService";
import PendingSkusView from "./PendingSkusView";
import { fetchProducts } from "../../../../services/productService";
import { fetchSellers } from "../../../../services/sellerService";
import OrdersWithInvoices from "./OrdersWithInvoices";

const PendingOrdersView: React.FC = () => {
    const { isLoadingOrdersWithNoInvoice, isLoadingPendingSkus, isLoadingOrdersWithInvoiceAndPendingProducts } = useAppSelector((s) => s.pendingReducer);
    const { isLoading: isLoadingProducts } = useAppSelector((s) => s.productReducer);
    const { isLoading: isLoadingSync } = useAppSelector((s) => s.invoiceReducer);

    const [tab, setTab] = useState<"noInvoice" | "pendingOrders" | "products">(
        "noInvoice");

    const [startDateSkus, setStartDateSkus] = useState<Date | null>(null);
    const [endDateSkus, setEndDateSkus] = useState<Date | null>(null);

    const [startDateOrders, setStartDateOrders] = useState<Date | null>(null);
    const [endDateOrders, setEndDateOrders] = useState<Date | null>(null);
    const [filterType, setFilterType] = useState<"order" | "invoice" | "">("");

    const effectRan = useRef(false);

    useEffect(() => {
        if (effectRan.current === false) {
            fetchOrdersWithNoInvoice();
            fetchPendingSkus();
            fetchProducts();
            fetchOrdersWithInvoiceAndPendingProducts();
            fetchSellers();
            return () => {
                effectRan.current = true;
            };
        }
    }, []);

    return (
        <div className="p-5 h-full overflow-auto">
            <Logo />
            <div className="text-xl mb-3">Ordenes Pendientes</div>
            {isLoadingOrdersWithNoInvoice || isLoadingPendingSkus || isLoadingProducts || isLoadingOrdersWithInvoiceAndPendingProducts || isLoadingSync ? (
                <div className="flex justify-center w-full col-span-full">
                    <Icon spin path={mdiCircleSlice1} className="w-7"></Icon>
                </div>
            ) : (
                <div className="h-full flex flex-col overflow-auto">
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                            value={tab}
                            onChange={(_, v) => {
                                setTab(v as "noInvoice" | "pendingOrders" | "products");
                            }}
                            aria-label="basic tabs example"
                        >
                            <Tab label="Ordenes sin factura" value={"noInvoice"} />
                            <Tab label="Ordenes Pendientes" value={"pendingOrders"} />
                            <Tab label="Productos" value={"products"} />

                        </Tabs>
                    </Box>
                    <div className="overflow-auto">
                        <Button variant="contained"
                            onClick={async () => {
                                await syncInvoicesLaudus();
                                fetchOrdersWithNoInvoice();
                                fetchPendingSkus();
                                fetchProducts();
                                fetchOrdersWithInvoiceAndPendingProducts();
                                fetchSellers();
                            }
                            }
                        >
                            Sincronizar Facturas
                        </Button>
                        {tab === "noInvoice" && <OrdersWithoutInvoicesView />}
                        {tab === "pendingOrders" && <OrdersWithInvoices
                            startDate={startDateOrders}
                            setStartDate={setStartDateOrders}
                            endDate={endDateOrders}
                            setEndDate={setEndDateOrders}
                            filterType={filterType}
                            setFilterType={setFilterType}
                        />}
                        {tab === "products" && <PendingSkusView
                            startDate={startDateSkus}
                            endDate={endDateSkus}
                            setStartDate={setStartDateSkus}
                            setEndDate={setEndDateSkus}
                        />}
                    </div>
                </div>
            )}
        </div>
    );
};

export default PendingOrdersView;