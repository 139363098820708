import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    TableBody,
} from '@mui/material';

interface Product {
    sku: string;
    description: string;
    newStock: number;
    oldStock: number;
}

interface Inventory {
    warehouse: string;
    date: Date;
    notes: string;
    products: Product[];
}

interface Props {
    open: boolean;
    onClose: () => void;
    inventory: Inventory | null;
}

const InventoryDetailModal: React.FC<Props> = ({ open, onClose, inventory }) => {
    if (!inventory) return null;

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Detalles del Inventario</DialogTitle>
            <DialogContent>
                <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                    Bodega: {inventory.warehouse}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                    Fecha: {new Date(inventory.date).toLocaleDateString()}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                    Notas: {inventory.notes}
                </Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>SKU</TableCell>
                            <TableCell>Descripción</TableCell>
                            <TableCell>Stock Antiguo</TableCell>
                            <TableCell>Stock Nuevo</TableCell>
                            <TableCell>Diferencia</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {inventory.products.map((product) => (
                            <TableRow key={product.sku}>
                                <TableCell>{product.sku}</TableCell>
                                <TableCell>{product.description}</TableCell>
                                <TableCell>{product.oldStock}</TableCell>
                                <TableCell>{product.newStock}</TableCell>
                                <TableCell>{product.newStock - product.oldStock}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="contained">Cerrar</Button>
            </DialogActions>
        </Dialog>
    );
};

export default InventoryDetailModal;
