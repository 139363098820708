import axios from "./client";

const API_URL = process.env.REACT_APP_API_URL;

// Sincronizar inventarios desde la API de Laudus
export const syncInventories = async () => {
    try {
        const response = await axios.post(`${API_URL}inventories/sync`);
        alert(response.data.message || "Sincronización completada.");
        return response.data;
    } catch (error: any) {
        const errorMessage = error?.response?.data?.message || "Error sincronizando inventarios.";
        alert(errorMessage);
        console.error("Error sincronizando inventarios:", error);
        return null;
    }
};

// Obtener inventarios por filtro de fechas y almacén
export const getInventoriesByFilter = async (startDate: Date, endDate: Date, warehouse?: string) => {
    try {
        const response = await axios.get(`${API_URL}inventories/filter`, {
            params: { startDate, endDate, warehouse }
        });
        return response.data;
    } catch (error: any) {
        const errorMessage = error?.response?.data?.message || "Error obteniendo inventarios por filtro.";
        alert(errorMessage);
        console.error("Error obteniendo inventarios por filtro:", error);
        return [];
    }
};

// Obtener inventarios por SKU específico
export const getInventoriesBySku = async (sku: string) => {
    try {
        const response = await axios.get(`${API_URL}inventories/by-sku/${sku}`);
        return response.data;
    } catch (error: any) {
        const errorMessage = error?.response?.data?.message || "Error obteniendo inventarios por SKU.";
        alert(errorMessage);
        console.error("Error obteniendo inventarios por SKU:", error);
        return [];
    }
};

// Obtener los SKU con mayor diferencia de stock
export const getTopSkuByStockDifference = async (startDate: Date, endDate: Date, limit: number = 100) => {
    try {
        const response = await axios.get(`${API_URL}inventories/top-sku-difference`, {
            params: { startDate, endDate, limit }
        });
        return response.data;
    } catch (error: any) {
        const errorMessage = error?.response?.data?.message || "Error obteniendo SKU con mayor diferencia de stock.";
        alert(errorMessage);
        console.error("Error obteniendo SKU con mayor diferencia de stock:", error);
        return [];
    }
};
