import { useEffect, useState } from 'react';
import {
    DataGrid,
    GridColDef,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    esES,
} from '@mui/x-data-grid';
import { Button, Typography } from '@mui/material';
import ComboBox from '../../../Combobox'; // Ajusta la ruta según tu estructura
import { getInventoriesBySku } from '../../../../services/inventoryService'; // Ajusta la ruta según tu estructura
import { fetchProducts } from '../../../../services/productService';
import { useAppSelector } from '../../../../hooks/storeHooks';


const CustomToolbar = () => (
    <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
    </GridToolbarContainer>
);

const InventoryBySkuView: React.FC = () => {
    const { products } = useAppSelector((state) => state.productReducer);
    const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
    const [inventories, setInventories] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchProducts();
    }, []);

    const handleSearch = async () => {
        if (!selectedProduct) {
            alert("Selecciona un SKU");
            return;
        }

        setLoading(true);
        try {
            const data = await getInventoriesBySku(selectedProduct.code);
            setInventories(data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const columns: GridColDef[] = [
        { field: 'date', headerName: 'Fecha', flex: 1, minWidth: 120, valueGetter: (params) => new Date(params.value).toLocaleDateString() },
        { field: 'warehouse', headerName: 'Bodega', flex: 1, minWidth: 150 },
        { field: 'notes', headerName: 'Notas', flex: 1, minWidth: 200 },
        { field: 'inventoryId', headerName: 'ID de Inventario', flex: 1, minWidth: 150 },
        { field: 'oldStock', headerName: 'Stock Antiguo', flex: 1, minWidth: 100 },
        { field: 'newStock', headerName: 'Stock Nuevo', flex: 1, minWidth: 100 },
        { field: 'difference', headerName: 'Diferencia', flex: 1, minWidth: 100, valueGetter: (params) => params.row.newStock - params.row.oldStock },
    ];


    return (
        <div className="p-5 h-full overflow-auto">
            <Typography variant="h6">Inventario por SKU</Typography>
            <div className="my-2 flex gap-2">
                <ComboBox<Product>
                    value={selectedProduct as Product}
                    options={products}
                    selectValue="code"
                    secondarySelectValue="description"
                    onChange={(product) => setSelectedProduct(product)}
                    label="Selecciona un SKU"
                />
                <Button variant="contained" onClick={handleSearch} disabled={loading || !selectedProduct}>
                    Buscar
                </Button>
            </div>
            <DataGrid
                autoHeight
                rows={inventories}
                columns={columns}
                pageSize={10}
                loading={loading}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                components={{
                    Toolbar: CustomToolbar,
                }}
                getRowId={(row) => row.inventoryId + row.warehouse + row.date}
            />
        </div>
    );
};

export default InventoryBySkuView;
