import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    TableBody,
} from '@mui/material';

interface Props {
    open: boolean;
    onClose: () => void;
    order: OrderWithInvoiceAndPendingProducts | null;
}

const InvoiceModal: React.FC<Props> = ({ open, onClose, order }) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Facturas Asociadas</DialogTitle>
            <DialogContent>
                <div className="flex flex-col gap-4 mt-5">
                    {order?.invoices.map((invoice) => (
                        <div key={invoice.invoiceLaudusId} style={{ marginBottom: '20px' }}>
                            <Typography variant="h6" gutterBottom>
                                Número de Factura: {invoice.invoiceLaudusId}
                            </Typography>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>SKU</TableCell>
                                        <TableCell>Cantidad</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {invoice.items.map((item) => (
                                        <TableRow key={item.sku}>
                                            <TableCell>{item.sku}</TableCell>
                                            <TableCell>{item.quantity}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    ))}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cerrar</Button>
            </DialogActions>
        </Dialog>
    );
};

export default InvoiceModal;
